<template>
  <section class="my-5">
    <div class="flex items-center">
      <back-button class="mr-2"/>
      <div class="font-bold text-xl">Ajustes Facturacion Electronica </div>
    </div>
    <div class="w-full my-5 box p-4">
      <form @submit="onSubmit">

        <!-- COMPRAS ADMINISTRATIVAS -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Administrativas</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.administrativos"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.administrativos }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.administrativos2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.administrativos }}</span>
        </div>

        <br>
        <!-- COMPRAS RECURSOS HUMANOS -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Recursos Humanos</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.recursos"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.recursos }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.recursos2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.recursos }}</span>
        </div>

        <br>
        <!-- COMPRAS CALIDAD -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Calidad</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.calidad"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.calidad }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.calidad2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.calidad }}</span>
        </div>

        <br>
        <!-- COMPRAS JURIDICAS -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Juridicas</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.juridica"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.juridica }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.juridica2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.juridica }}</span>
        </div>

        <br>
        <!-- COMPRAS INFRAESTRUCTURA -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Infraestructura</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.infraestructura"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.infraestructura }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.infraestructura2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.infraestructura }}</span>
        </div>

        <br>
        <!-- COMPRAS MEDICAMENTOS -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Medicamentos</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.medicamentos"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.medicamentos }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.medicamentos2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.medicamentos }}</span>
        </div>

        <br>
        <!-- COMPRAS SISTEMAS -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Sistemas</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.sistemas"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.sistemas }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.sistemas2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.sistemas }}</span>
        </div>

        <br>
        <!-- COMPRAS GERENCIA -->
        <div class="form-group">
          <span style="font-size: 18px">Compras Gerencia</span>
          <br>
          <span style="font-size: 14px">Quien Compra</span>
          <br>
          <Dropdown
            v-model="values.gerencia"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.gerencia }}</span>
          <br>
          <span style="font-size: 14px">Quien Autoriza</span>
          <br>
          <Dropdown
            v-model="values.gerencia2"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="Seleccione un usuario"
            :showClear="true"
            style="display: flex; width: 100%"
          />
          <span class="" style="color: darkred">{{ errors.gerencia }}</span>
        </div>
        <br>
        <div class="w-full">
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>

import { reactive, ref, onMounted } from 'vue'
import getCommonPeopleList from '../../../../services/getPeopleService'
import saveSettingsAdministrative from '../../../../services/saveSettingsAdministrativeService'
import userList from '../../../../services/userListService'
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'

export default {
  name: 'Facturacion',
  setup () {
    const peopleList = ref([])
    const userId = ref()
    const configFacturacion = reactive({
      administrativos: 0,
      recursos: 0,
      calidad: 0,
      juridica: 0,
      infraestructura: 0,
      medicamentos: 0,
      sistemas: 0,
      gerencia: 0,
      administrativos2: 0,
      recursos2: 0,
      calidad2: 0,
      juridica2: 0,
      infraestructura2: 0,
      medicamentos2: 0,
      sistemas2: 0,
      gerencia2: 0
    })

    onMounted(() => {
      apiCommonPeopleList()
      userList()
      .then(function (response) {
        values.administrativos = response.data[0].codUser
        values.administrativos2 = response.data[0].authUser
        values.recursos = response.data[1].codUser
        values.recursos2 = response.data[1].authUser
        values.calidad = response.data[2].codUser
        values.calidad2 = response.data[2].authUser
        values.juridica = response.data[3].codUser
        values.juridica2 = response.data[3].authUser
        values.infraestructura = response.data[4].codUser
        values.infraestructura2 = response.data[4].authUser
        values.medicamentos = response.data[5].codUser
        values.medicamentos2 = response.data[5].authUser
        values.sistemas = response.data[6].codUser
        values.sistemas2 = response.data[6].authUser
        values.gerencia = response.data[7].codUser
        values.gerencia2 = response.data[7].authUser
      })
      .catch(function (error) {
        console.log(error)
      })
    })

    const apiCommonPeopleList = async () => {
      const { data } = await getCommonPeopleList()
      peopleList.value = data ?? []
    }

    const schema = yup.object().shape({
      administrativos: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Administrativas'),
      recursos: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Recursos Humanos'),
      calidad: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Calidad'),
      juridica: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Juridicas'),
      infraestructura: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Infraestructura'),
      medicamentos: yup.number().required().label('Tipo invalido').typeError('Seleccione un usuario para Compras Medicamentos'),
      sistemas: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Sistemas'),
      gerencia: yup.number().required().label('Tipo documento').typeError('Seleccione un usuario para Compras Gerencia')
    })

    const { handleSubmit, values, errors } = useForm({
      initialValues: configFacturacion,
      validationSchema: schema
    })

    useField('administrativos')
    useField('recursos')
    useField('calidad')
    useField('juridica')
    useField('infraestructura')
    useField('medicamentos')
    useField('sistemas')
    useField('gerencia')

    const onSubmit = handleSubmit(values => {
      console.log(JSON.stringify(values, null, 2))
      saveSettingsAdministrative(values)
      .then(function (response) {
        console.log('Response:')
        if (response.data) {
          Swal.fire(
            'Exito!',
            'Se guardaron las configuraciones con éxito',
            'success'
          )
        } else {
          Swal.fire(
            'Error!',
            'Comuníquese con el área de sistemas',
            'success'
          )
        }
      })
      .catch(function (error) {
        console.log(error)
      })
    })

    return {
      configFacturacion,
      peopleList,
      userId,
      errors,
      onSubmit,
      values
    }
  }
}
</script>
